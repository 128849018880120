




















































































































































































import Bop from "@/models/Bop";
import BopModel from "@/models/BopModel";
import ComplementaryData from "@/models/ComplementaryData";
import HeightSupplement from "@/models/HeightSupplement";
import CoefficientOptionService from "@/services/CoefficientOptionService";
import CoefficientSelectedValueService from "@/services/CoefficientSelectedValueService";
import CountryService from "@/services/CountryService";
import OperationService from "@/services/OperationService";
import { BopModelEnum, OperationType } from "@/utils/Enums";
import { roundNumber } from "@/utils/helpers";
import { Guid } from "guid-typescript";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ComplementsCoutsSuppAdministrationService from "@/services/ComplementsCoutsSuppAdministrationService";
import ComplementsCoutsSupp from "@/models/ComplementsCoutsSuppAdministration";
import BopSetupTable from "@/components/Shared/Table/BopSetupTable.vue";
type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};

@Component({
  components: {
    BopSetupTable: () => import("@/components/Shared/Table/BopSetupTable.vue"),
  },
})
export default class OperationSuppStep extends Vue {
  @Prop()
  private isNotReadOnly!: boolean;
  private get canBeEditedAfterRealised():boolean{
    return (this.$route.query.isEdit !== undefined && this.$route.query.isEdit === 'true');
  }
  private get isEditable(): boolean{
    return this.canBeEditedAfterRealised || this.isNotReadOnly;
  };
  private operations: Array<any> = [];
  private epiOperations: Array<any> = [];
  // private SuppHauteurOperations: Array<any> = [];
  private modifiedOperations: Array<any> = [];
  private modifiedEpiOperations: Array<any> = [];
  private modifiedSuppHauteurOperations: Array<any> = [];

   private get bopShort(): String {
    return `${this.bop.siteName} - ${this.bop.bopModelName?.toString().toLowerCase()} ${this.bop.bopNumber ? " # ":""}${this.bop.bopNumber ?? ""}`;
  }
  //contains the available complement keys using the typetravaux
  private  allComplementsCoutsSuppConfigs: Array<ComplementsCoutsSupp> = [];
  private  complementsCoutsSuppConfig:Array<string> = [];

  private currencySymbole:string = "";
  private get isTuyauterieMetaliqueBop() {
    return this.bop.bopModelKey === BopModelEnum.TuyauterieMetalique;
  }

  private get isElectriciteInstrumentationBop() {
    return this.bop.bopModelKey === BopModelEnum.ElectriciteInstrumentation;
  }
  private get totalPointsEpi() {
    return this.$store.state.bop.totalOperationSuppEpiPoints;
  }

  private get totalOperationSuppEpiPoints() {
    return this.$store.state.bop.totalOperationSuppEpiPoints;
  }
  private set totalPointsEpi(value: any) {
     if(!this.isEditable)return;
    this.$store.commit('changeTotalOperationSuppEpiPoints',value);

  }
  private get totalPoints() {
    return this.$store.state.bop.totalOperationSuppHeurePoints;
  }
  private set totalPoints(value: any) {
     if(!this.isEditable)return;
    this.$store.commit('changeTotalOperationSuppHeurePoints',value);
  }
    private get totalSuppHauteurPoints() {
    return this.$store.state.bop.totalSuppHauteurPoints;
  }
  private set totalSuppHauteurPoints(value: any) {
     if(!this.isEditable)return;
    this.$store.commit('changetotalSuppHauteurPointsPoints',value);
  }
  private data: Data = {
    columns: [],
    rows: [],
  };
  private dataEpi: Data = {
    columns: [],
    rows: [],
  };
  private dataSuppHauteur: Data = {
    columns: [],
    rows: [],
  };
  private get bop(): Bop {
    return this.$store.state.bop;
  }
  private get totalPointsSum() {
    const total  = this.totalPointsEpi + this.totalPoints + (this.totalSuppHauteurPoints ?? 0)
    return   roundNumber(total);
  }
  private set bop(value: Bop) {
    this.$store.commit("changeBop", value);
  }
  private get bopStatus(){
    return this.$store.state.bopStatuses[this.bop.bopStatusKey];
  }
  private get selectedBopModel():BopModel{
    return this.$store.state.selectedBopModel;
  }
  private get allOperations(): Array<any> {
    return this.modifiedOperations.concat(this.modifiedEpiOperations);
  }
  private get isBopReadOnly(): boolean {
    return Bop.readOnlyStatuses.includes(this.bop.bopStatusKey) && !this.canBeEditedAfterRealised;
  }

  private get pointValue(): number {
    return this.bop.pointValue ?? 0; // Default to 0 if not set
  }

  /**
   * #########
   * Methods
   * #########
   */
   private updatePointValue(value: string | number): void {
    const parsedValue = parseFloat(value.toString());
    if (isNaN(parsedValue) || parsedValue < 0) {
      // Reset the value reactively to 0 and notify the user
      this.bop.pointValue = 0;
      this.$store.commit("changeBop", this.bop);
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("bop.pointValueValidationError.title").toString(),
        text: this.$t("bop.pointValueValidationError.message").toString(),
      });
    } else {
      // Update the value reactively
      this.bop.pointValue = parsedValue;
      this.$store.commit("changeBop", this.bop);
    }
  }

  private addHeightSupplement(){
    const newHeightSupplement = new HeightSupplement();
    (<any>this.$refs.tableHauteur).updatedRows.unshift(newHeightSupplement);
    (<any>this.$refs.tableHauteur).modify(newHeightSupplement);
  }
  private save() {}
  private onTotalPointsChanged(total: any) {
    this.totalPoints = total;
  }
  private onTotalPointsEpiChanged(total: any) {
    this.totalPointsEpi = total;
  }
  private onTotalPointsHeightSupplementChanged(total:any){
    this.totalSuppHauteurPoints = total;
  }


  private async loadData(){
    if(!this.bop.bopModelId || !this.bop.siteId)
    return;
    if(this.isTuyauterieMetaliqueBop){
      this.bop.complementaryData ??= new ComplementaryData();
      const country = (await CountryService.getCountryBySite(this.bop.siteId)).data;
      this.currencySymbole = country.currencySymbol
    }else{
      this.bop.complementaryData = null;
    }
    //  let selectedOperation = this.bop.operationSuppGroups[0].operations;
    const selectedCoeffValues: Array<any> = (
      await CoefficientSelectedValueService.GetCoefficientType5ValuesBysite(
        this.bop.bopModelId,
        this.bop.siteId
      )
    ).data;
    let currentOperationDictionary: any = {};
    if (this.bop.operationSuppGroups[0].operations.length) {
      this.bop.operationSuppGroups[0].operations.forEach((o) => {
        currentOperationDictionary[o.operationId] = {
          id: o.id,
          quantity: o.quantity,
          pointsPerHour: o.pointsPerHour
        };
      });
    }
    const allOperations = (
      await OperationService.getBopOperationsSupp(this.$i18n.locale)
    ).data;
    let operations: Array<any> = [];
    let epiOperations: Array<any> = [];
    allOperations.forEach((el: any) => {
      const selectedCoeffValue = selectedCoeffValues.find(
        (sc) => sc.coefficientKey == el.code
      );
      if((!this.isBopReadOnly && !selectedCoeffValue) || (this.isBopReadOnly && !currentOperationDictionary[el.id]))return;
      const op = {
        id: currentOperationDictionary[el.id]
          ? currentOperationDictionary[el.id].id
          : Guid.create().toString(),
        name: el.name,
        quantity: currentOperationDictionary[el.id]
          ? currentOperationDictionary[el.id].quantity
          : 0,
        operationId: el.id,
        code: el.code,
        coefficientId: this.isBopReadOnly? "" : selectedCoeffValue.id,
        pointsPerHour: this.isBopReadOnly ? currentOperationDictionary[el.id].pointsPerHour : selectedCoeffValue.value,
        totalPoints: 0,
      };
      if (el.code === "C4") {
        epiOperations.push(op);
      } else {
        operations.push(op);
      }
    });
    const communData = [
      {
        name: "editable",
        title: this.$t("bopSetup.operationSupp.hours").toString(),
        property: "quantity",
        isNumber: true,
        isNotEditable: !this.isEditable
      },
      {
        name: "disabledDynamicInput",
        title: this.$t("bopSetup.operationSupp.pointsPerHour").toString(),
        formula: "pointsPerHour",
      },
      {
        name: "disabledDynamicInput",
        title: this.$t("bopSetup.operationSupp.totalPoints").toString(),
        property: "totalPoints",

        formula: "{quantity} * {pointsPerHour}",
      },
    ];
    this.dataEpi.columns = communData;
    this.data.columns = [
      {
        name: "disabledDynamicInput",
        title: this.$t("bopSetup.operationSupp.intervention").toString(),
        formula: "name",
      },
      ...communData,
    ];
    this.operations = operations;
    this.epiOperations = epiOperations;

    if(this.isElectriciteInstrumentationBop){
      const coeffOptionsHauteur =
      (await CoefficientOptionService
      .GetCoefficientOptionsByKey('hauteur',this.selectedBopModel.id,this.$i18n.locale)
      ).data.map(
        (el:any)=>{
          return {
            key: el,
            value: el.name
          }
        }
      );
      const columns = [
        {
            name: "bopDropDown",
            title: this.$t("bopSetup.operationSupp.hauteur"),
            property: 'coefficientOptionId',
            // display: "operationName",
            options: coeffOptionsHauteur,
            dataContainer: "coefficientOption",
            valueField: "key.id",
            textField: "value",
            isNotEditable: !this.isEditable,
        }
        ,
        {
          name: "editable",
          isNumber: true,
          title: this.$t("bopSetup.operationSupp.hours").toString(),
          property: "quantity",
          isNotEditable: !this.isEditable
        },
        {
          name: "disabledDynamicInput",
          title: this.$t("bopSetup.operationSupp.totalPoints").toString(),
          property: "totalPoints",
          formula: "{coefficientOption.value} * {quantity} ",
        },
        {
          name: "actions",
          title: "",
          removeButton: true,
        },
      ];
      this.dataSuppHauteur.columns = columns
      // this.bop.heightSupplements ??= []
    }
    if(this.isTuyauterieMetaliqueBop ) {
      this.allComplementsCoutsSuppConfigs = (await ComplementsCoutsSuppAdministrationService.getComplementsCoutsSuppAdministration(this.$i18n.locale)).data;
      this.$watch("bop.typeTravaux", this.loadFilteredConfig);
      this.loadFilteredConfig();

    }

  }
  private loadFilteredConfig (){
  const filteredConfig =  this.allComplementsCoutsSuppConfigs.find(c=> c.typeTravauxId === this.bop.typeTravaux)?.complementsCoutsSuppKeys;
  this.complementsCoutsSuppConfig=  filteredConfig ?? [];
}

public handleRemove(props: any) {
  (<BopSetupTable>this.$refs.tableHauteur).removeRow(props.rowIndex);
  this.modifiedSuppHauteurOperations.splice(props.rowIndex, 1);
}

/**
 * ########
 * Hooks
 * #######
 */
private created() {
    this.loadData().then(()=>{

      this.totalPointsEpi = this.epiOperations.length === 0 ? 0 : this.bop.totalOperationSuppEpiPoints;
      this.totalPoints = this.operations.length === 0 ? 0 : this.operations.reduce((a,b)=> {
        return a + (b.pointsPerHour * b.quantity)
      },0);
    })
}
  //watchers
  @Watch("selectedBopModel.key")
  private async onBopModelChange(){
    await this.loadData();
  }
  @Watch("allOperations")
  onModifiedOperationChange() {
    let operations: Array<any> = [];
    this.allOperations.forEach((el: any) => {
      operations.push({
        id: el.id,
        code: el.code,
        operationId: el.operationId,
        quantity: el.quantity,
        pointsPerHour: el.pointsPerHour?.toString()
      });
    });
    this.bop.operationSuppGroups[0].operations = operations;
  }
  @Watch("modifiedSuppHauteurOperations")
  onDataSuppHauteurChange(){
    this.bop.heightSupplements = [...this.modifiedSuppHauteurOperations]
  }
}
